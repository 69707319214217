
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";
export default {
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      popup: false,
    };
  },
  computed: {
    ...mapGetters(["isDev"]),
  },
  watch: {
    $route() {
      this.popup = false;
    },
  },
};
